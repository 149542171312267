import {timer} from 'rxjs';
import {filter, skipUntil, take} from 'rxjs/operators';
import {NavigationStart, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from '@ngxs/store';
import {get} from 'lodash';
import {
  BackendError,
  CleanByIndex,
  ClearMessages,
  DebugMessage,
  ErrorMessage,
  InfoMessage,
  SubscribeToRouter,
  SuccessMessage,
  WarrningMessage
} from './messages.actions';
import {Settings} from '../../conf/settings.base';


export const enum MessageType {
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning',
  DEBUG = 'debug',
  INFO = 'info',
}

export class Message {

  constructor(
    public type: string,
    public text: string,
    public category?: string,
    public data?: any,
  ) { }
}

export class MessagesStateModel {
  messages: Message[];
  backendError: any;
}

@State<MessagesStateModel>({
  name: 'messages',
  defaults: {
    messages: [],
    backendError: null,
  },
})

@Injectable()
export class MessagesState implements NgxsOnInit {

  navigationStart$;

  constructor(private store: Store, private router: Router, private setting: Settings) {
  }

  @Selector()
  static getMessages(state: MessagesStateModel): Message[] {
    return state.messages;
  }

  @Selector()
  static getBackendError(state: MessagesStateModel): any {
    return state.backendError;
  }

  @Selector()
  static getErrorMessages(state: MessagesStateModel): Message[] {
    return state.messages.filter(data => data.type === MessageType.ERROR);
  }

  @Selector()
  static getSuccessMessages(state: MessagesStateModel): Message[] {
    return state.messages.filter(data => data.type === MessageType.SUCCESS);
  }

  ngxsOnInit(ctx: StateContext<MessagesStateModel>) {
  }

  @Action([InfoMessage, WarrningMessage, ErrorMessage, DebugMessage, SuccessMessage])
  creatMessage(ctx: StateContext<MessagesStateModel>, message) {
    ctx.dispatch(new SubscribeToRouter());
    const newMessage = new Message(message.messageType, message.text);
    const state = ctx.getState();
    ctx.patchState({
      messages: [...state.messages, newMessage],
    });
  }

  @Action(BackendError)
  backendError(ctx: StateContext<MessagesStateModel>, data: BackendError) {
    ctx.patchState({
      backendError: data.error,
    });

    const nonFieldErrors = get(data, 'error.error.non_field_errors', []);
    for (const text of nonFieldErrors) {
      ctx.dispatch(new ErrorMessage(text));
    }
  }

  @Action(ClearMessages)
  onClearingState(ctx: StateContext<MessagesStateModel>) {
    const state = ctx.getState().messages;
    if (state.length === 1) {
      this.navigationStart$.unsubscribe();
    }
    ctx.patchState({
      messages: [],
      backendError: null,
    });
  }

  @Action(SubscribeToRouter)
  onSubscribeToRouter(ctx: StateContext<MessagesStateModel>) {
    this.navigationStart$ = this.router.events.pipe(
      filter(event => (event instanceof NavigationStart)),
      skipUntil(timer(this.setting.DELAY_TIME)),
      take(1),
    ).subscribe(() => ctx.dispatch(new ClearMessages()));
  }

  @Action(CleanByIndex)
  cleanByIndex(ctx: StateContext<MessagesStateModel>, index: CleanByIndex) {
    const state = ctx.getState().messages;
    if (state.length === 1) {
      ctx.dispatch(new ClearMessages());
    } else if (!!state.length && state.length !== 1) {
      ctx.patchState({
        messages: state.filter((message, i) => i !== index.index),
      });
    }
  }
}
